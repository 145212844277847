<template>
  <auth>
    <div class="right-content" id="main-content">
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> 
          <div class="brand-name"><span>Co-op</span><span>Bid</span></div>
          <small>Email sent</small>
        </div>
        <div class="icon">
          <i class="fa fa-sign-in"></i>
        </div>
      </div>
      <div class="alert alert-success">
        <p>
          An email has been sent to {{ $auth.getVerifyUser() }}. Please click on
          the link in your email to continue.
        </p>
        <div v-if="can_request_resend" class="text-center">
          <button
            class="btn btn-block btn-lg btn-primary"
            type="button"
            @click="resend"
          >
            Resend verification email.
          </button>
        </div>
        <div v-if="!can_request_resend" class="text-center">
          <p>
            Wait <span>{{ timerCount }}</span> seconds and retry if no email was
            received
          </p>
        </div>
      </div>
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      can_request_resend: false,
      timerCount: 60,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.can_request_resend = true;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    resend() {
      this.can_request_resend = false;
      this.timerCount = 60;
      this.$axios
        .post("/api/v1/resend-email", {
          email: this.$auth.getVerifyUser(),
        })
        .then(() => {
          this.$toastr.s("Email sent");
        });
    },
  },
};
</script>
